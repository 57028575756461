export const support_requests = {
    getAll: {
        url: 'support_request/getAllSupportRequest',
        method: 'GET',
        id: null,
        params: null
    },
    acceptRequest: {
        url: 'admin/support_request/acceptRequest/',
        method: 'GET',
        id: null,
        params: null
    },
    rejectRequest: {
        url: 'admin/support_request/rejectRequest/',
        method: 'GET',
        id: null,
        params: null
    }
}