<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { support_requests } from "@/config/api/support_request";
// $('a[data-toggle="dropdown"]').click(function() {
//   dropDownFixPosition($(this), $('.dropdown-menu'));
// });

export default {
  page: {
    title: "Support Request",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  name: "app",
  data() {
    return {
      title: "Support Request",
      supportData: [],
      items: [
        {
          text: "Support Request",
          active: true,
        },
        {
          text: "",
        },
      ],
      loading: false,
      supportRequestData: [],
      selectedRequest: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
    };
  },
  created() {
    this.loadData();
    if (this.$route.query.user_id) {
      console.log(this.$route.query.user_id);
    } else {
      console.log("No");
    }
  },
  computed: {
    rows() {
      return this.totalRows;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.supportData.length;
  },
  methods: {
    updatePage(page) {
      this.loadData(page);
    },
    async loadData(page = 1) {
      this.supportData = [];
      this.loading = true;
      try {
        const api = support_requests.getAll;
        console.log("this.$route.query.user");
        console.log(this.$route.query.user);
        var filter = { page: page };
        //TODO: BETTER GET FROM STATE
        let loggedInUser = JSON.parse(localStorage.getItem("user"));
        // We need to get logged in user details
        if (loggedInUser.user.companies_owned != null) {
          filter["user"] = loggedInUser.user._id;
        }
        console.log(filter);
        api.params = filter;

        let res = await this.generateAPI(api);
        this.supportData = res.data.support_requests.docs;
        this.totalRows = res.data.support_requests.totalDocs;
        this.loading = false;
      } catch (error) {
        console.error(error);
      }
    },
    navigate(path, id) {
      this.$router.push({
        path: path,
        query: { id: id },
      });
    },
    statusText(status) {
      switch (status) {
        case -1:
          return "Rejected";
        //   break;
        case 0:
          return "Pending";
        //   break;
        case 1:
          return "Accepted";
        //   break;
        case 2:
          return "Completed";
        default:
          return "Unknown";
        //   break;
      }
    },
    updateStatus(id) {
      this.acceptRequest(id);
    },

    async acceptRequest(id) {
      this.supportData = [];
      this.loading = true;
      try {
        const api = support_requests.acceptRequest;
        api.id = id;
        let res = await this.generateAPI(api);
        console.log(res);
        this.loading = false;
      } catch (error) {
        console.error(error);
      }
      window.location.reload();
    },
    async rejectRequest(id) {
      this.supportData = [];
      this.loading = true;
      try {
        const api = support_requests.rejectRequest;
        api.id = id;
        let res = await this.generateAPI(api);
        console.log(res);
        this.loading = false;
      } catch (error) {
        console.error(error);
      }
      window.location.reload();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- <div class="row justify-content-end">
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center">
            Search:
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Search..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
        </div>
      </div>
    </div> -->
    <b-table :busy="loading">
      <template #table-busy>
        <div style="text-align: center" class="mt-5 mb-5">
          <pulse-loader color="#505d69" :loading="true"></pulse-loader>
        </div>
      </template>
    </b-table>

    <div class="row center-items">
      <!-- <div class="col-lg-12"> -->
      <!-- <div class="card">
          <div class="card-body pt-0"> -->
      <div
        class="card-body my-width"
        v-for="(object, index) in supportData"
        :key="index"
      >
        <b-card>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 15px;
            "
          >
            <b-card-text>
              <span class="bold-it">Request ID:</span>
              {{ object._id == null ? "-" : object._id }}
            </b-card-text>
            <b-dropdown :id="'dropdown-' + index" size="lg" variant="rayhan">
              <template #button-content>
                <p
                  style="
                    font-size: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 10px;
                  "
                >
                  ...
                </p>
              </template>
              <b-dropdown-item
                style="color: white"
                @click="
                  $router.push({
                    path: `project-info`,
                    query: { id: object.project_id.project_id },
                  })
                "
                >View Project</b-dropdown-item
              >
              <b-dropdown-item
                @click="
                  $router.push({
                    path: `user-info`,
                    query: { id: object.user._id },
                  })
                "
                >View User</b-dropdown-item
              >
            </b-dropdown>
          </div>
          <b-card-text v-if="object.user">
            <span class="bold-it">Name:</span>
            {{ object.user.name == null ? "-" : object.user.name }}
          </b-card-text>
          <b-card-text v-else
            ><span class="bold-it">Name:</span> -
          </b-card-text>
          <b-card-text>
            <span class="bold-it">Status:</span> {{ statusText(object.status) }}
          </b-card-text>
          <!-- {{ object }} -->
          <b-card-text>
            <span class="bold-it"
              >Created At: {{ moment(object.createdAt).format("l") }}</span
            >
          </b-card-text>
          <b-card-text>
            <b-button variant="success" @click="acceptRequest(object._id)"
              >Accept</b-button
            >
            &nbsp;
            <b-button variant="danger" @click="rejectRequest(object._id)"
              >Reject</b-button
            >
          </b-card-text>
        </b-card>
      </div>
      <!-- </div>
        </div> -->
      <!-- </div> -->
    </div>
    <div class="row mb-4 justify-content-end">
      <div class="dataTables_paginate paging_simple_numbers float-right">
        <ul class="pagination pagination-rounded mb-0">
          <!-- pagination -->
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            @change="updatePage"
          ></b-pagination>
        </ul>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.my-width {
  min-width: 400px;
  max-width: 400px;
}
.bold-it {
  font-weight: bolder;
}
#test {
  margin-top: 10rem;
  height: 70px;
  overflow: scroll;
}
a {
  margin-left: 40%;
}
i:hover {
  color: #4285f4;
}

.dropdown-menu {
  position: fixed;
}

.dropdown .dropdown-menu .dropdown-item {
  padding: 1rem;
  margin-left: 0;
}
</style>

<style>
.dropdown .dropdown-menu a:hover {
  color: white;
  background: unset;
}
.dropdown-item {
  color: #8590a5;
}
.justify-content-end {
  justify-content: end;
}
</style>
